import React from 'react';
import { Text, Box, Center, Button, VStack, HStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

function BackTo(props) {
  return (
    <Box
      textAlign="left"
      width={{ base: '95%', md: '90%', lg: '75%', xl: '60%' }}
    >
      <HStack>
        <Link to={props.link}>
          <Button mt={10} size="lg" colorScheme="purple" variant="solid">
            <FaArrowLeft /> <Text ml={3}>{props.text}</Text>
          </Button>
        </Link>
        <Box w={5} />
        <Link to="/">
          <Button mt={10} size="lg" colorScheme="purple" variant="outline">
            <FaArrowLeft /> <Text ml={3}>Dashboard</Text>
          </Button>
        </Link>
      </HStack>
    </Box>
  );
}

export default BackTo;

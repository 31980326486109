import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userId: '',
  isLoggedIn: false,
  isAdmin: false,
  firstName: '',
  lastName: '',
  email: '',
  lessonsStats: {
    lastLesson: '',
    lastModule: '',
  },
  fullVideoStats: {},
};

const checkCurrentUserSlice = createSlice({
  name: 'checkCurrentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }) => {
      state.userId = payload.userId;
      state.isLoggedIn = payload.isLoggedIn;
      state.isAdmin = payload.isAdmin;
    },
    setCurrentUserPersonalData: (state, { payload }) => {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.lessonsStats = payload.lessonsStats;
      state.fullVideoStats = payload.fullVideoStats;
    },
    setCurrentUserlessonsStats: (state, { payload }) => {
      state.lessonsStats.lastLesson = payload.lastLesson;
      state.lessonsStats.lastModule = payload.lastModule;
    },
    setCurrentUserOverallVideoStats: (state, { payload }) => {
      state.fullVideoStats[payload.videoId] = payload.percent;
    },
    clearState: state => {
      state.userId = '';
      state.isLoggedIn = false;
      state.isAdmin = false;
      state.firstName = '';
      state.lastName = '';
      state.email = '';
      state.lessonsStats = {
        lastLesson: '',
        lastModule: '',
      };
      state.fullVideoStats = {};
    },
  },
});

export default checkCurrentUserSlice.reducer;
export const {
  setCurrentUser,
  setCurrentUserPersonalData,
  setCurrentUserlessonsStats,
  setCurrentUserOverallVideoStats,
  clearState,
} = checkCurrentUserSlice.actions;

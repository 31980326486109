import React, { useEffect, useState } from 'react';
import {
  Heading,
  Box,
  Center,
  VStack,
  HStack,
  Spacer,
  List,
  ListItem,
  Button,
  Text,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import Countdown from 'react-countdown';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useLocation, Navigate, useNavigate, Link } from 'react-router-dom';
import { customData } from '../../constants.js';

function SimpleVideo() {
  const navigate = useNavigate();

  const urlLocation = useLocation(); // React Hook
  const [lessonURL, setLessonURL] = useState('');
  const [sessionNo, setSessionNo] = useState(1);

  const number = urlLocation.pathname.trim().split('/')[2];
  const module = urlLocation.pathname.trim().split('/')[1];

  useEffect(() => {
    console.log(number);
    setSessionNo(number);
    setLessonURL(customData[module]['videos'][number - 1]);
  }, [number, module]);

  return (
    <VStack>
      <Heading size="xl" mt={10}>
        {customData[module].chapter}
      </Heading>
      <Heading pb="5" size="lg">
        Partea #{parseInt(number)}
      </Heading>
      <Center>
        {' '}
        <Box
          m={7}
          width={{ base: '95%', md: '90%', lg: '75%' }}
          borderRadius="15px"
          bg={'gray.200'}
          textAlign={'center'}
        >
          <ReactPlayer
            className="react-player"
            width="100%"
            height="100%"
            key={lessonURL}
            url={lessonURL}
            preload="metadata"
            playing
            controls
          />
        </Box>
      </Center>
      <Heading pb="5" size="lg">
        Cuprins {module}
      </Heading>
      <List width={{ base: '95%', md: '90%', lg: '75%' }} spacing={3}>
        {customData[module].videos.map((e, i) => {
          console.log(sessionNo, parseInt(i + 1));
          return (
            <ListItem>
              <Box px={3} lineHeight={7} borderRadius={'12px'}>
                <Button
                  colorScheme={'purple'}
                  bg={'purple.500'}
                  w={'100%'}
                  onClick={() => {
                    navigate(`/${module}/${i + 1}`);
                    setSessionNo(i + 1);
                  }}
                  _hover={{ background: 'purple.300' }}
                >
                  <HStack>
                    <Text
                      fontWeight={
                        parseInt(sessionNo) === parseInt(i + 1)
                          ? 'bold'
                          : 'normal'
                      }
                    >
                      {customData[module].titles[i]}
                    </Text>
                  </HStack>
                </Button>
              </Box>
            </ListItem>
          );
        })}
        /
      </List>

      <br />
    </VStack>
  );
}

export default SimpleVideo;

import React from 'react';
import {
  ButtonGroup,
  Container,
  IconButton,
  Image,
  Stack,
  Text,
  Box,
  HStack,
  Heading,
  Spacer,
  Center,
  VStack,
  Divider,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
} from '@chakra-ui/react';
import {
  modules,
  homeworkTitle,
  exercisesDictionary,
} from '../../constants.js';
import { Link, useLocation } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import BackToDashboard from '../../components/BackToDashboard/BackToDashboard.jsx';
import BackTo from '../../components/BackTo/BackTo.jsx';

function importAll(r) {
  let images = {};
  r.keys().map(item => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

function Lesson() {
  const urlLocation = useLocation(); // React Hook
  const module = urlLocation.pathname.trim().split('/')[2];

  let moduleImages = [];
  console.log(module);
  let images = importAll(require.context('./../../images/lessons/'));

  for (let image of Object.entries(images)) {
    if (image[0].split('/')[0].includes(`${module}`)) {
      moduleImages.push(image);
    }
  }
  moduleImages.sort((a, b) =>
    a[0].localeCompare(b[0], undefined, { numeric: true })
  );
  console.log(moduleImages);

  return (
    <>
      <Container maxW="container.xl">
        {' '}
        <BackTo link="/exercitii" text="Listă Capitole Teme" />
      </Container>

      <Center>
        <VStack width={{ base: '95%', md: '90%', lg: '75%', xl: '60%' }}>
          {/* <Heading size="lg" >
          Exercitii Modului #1
        </Heading> */}

          <Heading mt={10} size="lg">
            {homeworkTitle[module - 1]}
          </Heading>
          <Box height={13} />
          <Tabs variant="enclosed-colored" colorScheme="purple">
            <TabList>
              {exercisesDictionary[module].map(e => (
                <Tab>{e}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {exercisesDictionary[module].map((e, i) => (
                <TabPanel>
                  {moduleImages.map((e, index) => {
                    if (e[0].includes(String.fromCharCode(i + 97))) {
                      return (
                        <VStack key={index}>
                          <Box height={5} />
                          <Heading size="lg">
                            Exercițiul #
                            {e[0].split('/')[1].split('.')[0].split('_')[1]}
                          </Heading>
                          <Box height={5} />
                          <HStack>
                            <Image src={e[1]} />;
                          </HStack>
                          <Box height={7} />
                          <Divider />
                        </VStack>
                      );
                    }
                  })}
                </TabPanel>
              ))}
              {/* <TabPanel>
             
            </TabPanel> */}
            </TabPanels>
          </Tabs>
        </VStack>
      </Center>
    </>
  );
}

export default Lesson;

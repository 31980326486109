import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import { extendTheme } from '@chakra-ui/react';
import '@fontsource/ibm-plex-sans'; // Defaults to weight 400.
import { store, persistor } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
const theme = extendTheme({
  fonts: {
    heading: `'IBM Plex Sans', sans-serif`,
    body: `"IBM Plex Sans", Helvetica, sans-serif`,
  },
  components: {
    Table: {
      variants: {
        simple: {
          th: {
            border: 'solid',
            borderColor: 'gray.500',
          },
          td: {
            border: 'solid',
            borderColor: 'gray.500',
          },
        },
        striped: {
          th: {
            border: '1px solid',
            borderColor: 'gray.500',
          },
          td: {
            border: '1px solid',
            borderColor: 'gray.500',
          },
          tbody: {
            tr: {
              _odd: {
                background: 'gray',
                td: {
                  border: '1px solid',
                  borderColor: 'gray.500',
                },
              },
              // _even: {
              //   background: 'gray',
              //   td: {
              //     border: '1px solid',
              //     borderColor: 'gray.500',
              //   },
              // },
            },
          },
        },
      },
    },
  },
});

root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </ChakraProvider>
  </Provider>
);

import React from 'react';
import BackToDashboard from '../../components/BackToDashboard/BackToDashboard';
import {
  Center,
  VStack,
  Heading,
  Card,
  Image,
  Stack,
  Text,
  CardBody,
  Button,
  CardFooter,
  Container,
} from '@chakra-ui/react';
import {

  sessionModule,
} from '../../constants';
import { Link, useNavigate } from 'react-router-dom';
import BackTo from '../../components/BackTo/BackTo';
import HorizontalImageCard from '../../components/HorizontalImageCard/HorizontalImageCard';

function LiveLessonIndex() {
  return (
    <Center>
      <VStack>
        <Container maxW="container.xl">
          {' '}
          <BackToDashboard />
        </Container>
        <Heading size="xl" mt={10}>
          Sesiuni Live
        </Heading>
        <Container maxW="container.xl">
          {sessionModule.map((k, i) => {
            return (
              <HorizontalImageCard
                thumbnail={`https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Thumbnails_Live/live_${i+1}.png`}
                title={`#${i+1} ${k}`}
                footer={''}
                link={`/sesiuni-live/${i}`}
                buttonText="Mergi la Video"
              />
            );
          })}
        </Container>
      </VStack>
    </Center>
  );
}

export default LiveLessonIndex;

import React, { useState } from 'react';
import {
  Container,
  Heading,
  Box,
  Center,
  VStack,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Button,
  HStack,
  Spacer,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Image,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import Countdown from 'react-countdown';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AiFillCheckCircle } from 'react-icons/ai';

function SimpleOneVideoPage() {
  const [isCompleted, setIsCompleted] = useState(false);

  const Completionist = () => <span>You are good to go!</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsCompleted(true);
      days = 0;
      hours = 0;
      minutes = 0;
      seconds = 0;
    } else {
      setIsCompleted(false);
    }
    return (
      <>
        <Box h={'50px'} />
        <HStack maxW="700px" textAlign={'center'} margin="auto">
          <Spacer />
          <CircularProgressbar
            maxValue={2}
            value={days}
            text={`${days} zile`}
            styles={buildStyles({
              // Colors
              pathColor: '#ff0000',
              textColor: '#171923',
              trailColor: '#FED7D7',
            })}
          />
          ;
          <CircularProgressbar
            maxValue={24}
            value={hours}
            text={`${hours} ore`}
            styles={buildStyles({
              // Colors
              pathColor: '#ff0000',
              textColor: '#171923',
              trailColor: '#FED7D7',
            })}
          />
          ;
          <CircularProgressbar
            maxValue={60}
            value={minutes}
            text={`${minutes} min`}
            styles={buildStyles({
              // Colors
              pathColor: '#ff0000',
              textColor: '#171923',
              trailColor: '#FED7D7',
            })}
          />
          ;
          <CircularProgressbar
            maxValue={60}
            value={seconds}
            text={`${seconds} sec`}
            styles={buildStyles({
              // Colors
              pathColor: '#ff0000',
              textColor: '#171923',
              trailColor: '#FED7D7',
            })}
          />
          ;
          <Spacer />
        </HStack>
      </>
    );
  };

  return (
    <VStack>
      <Heading size="xl" mt={10}>
        Câștigătorul giveaway-ului esteeee....
      </Heading>
      <Heading pb="5" size="lg">
        Felicitări tuturor participanților! 🎉🎉🎉
      </Heading>
      <Center>
        {' '}
        <Box
          m={7}
          width={{ base: '95%', md: '90%', lg: '75%' }}
          borderRadius="15px"
          bg={'gray.200'}
          textAlign={'center'}
        >
          <ReactPlayer
            className="react-player"
            width="100%"
            height="100%"
            key="https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Giveaway_Announcement.mp4"
            url="https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Giveaway_Announcement.mp4"
            preload="metadata"
            playing
            controls
          />
        </Box>
      </Center>
      <Heading color="red" size="lg" pt={10}>
        Nu fii trist! Chiar dacă nu ai câștigat...
      </Heading>
      <Heading color="red" size="xl" pt={5}>
        Îți ofer GRATUIT și ție aceste caiete + 100 RON CADOU!
      </Heading>
      <br />
      {/* <Box maxW="1000px" mx={7} px={7} textAlign={'left'}>
        <Heading size="md">
          Știu că ți-ai dorit enorm acest pachet de caiete. <br />
          <br /> De aceea te-ai înscris în concurs, așa este? <br />
          <br />
          Am citit foarte multe mesaje de la voi în care mi-ați spus acest
          lucru. Si m-am decis să fac ceva în această privință. <br />
          <br />
          Pentru că nu vreau sp rămâi cu un gust amar. Și pentru că eu chiar
          vreau să te ajut. <br />
          <br />
          Vreau ca pregătirea pentru bacul la informatică să fie cât mai plăcută
          și pe înțelesul tău.
        </Heading>
      </Box> */}
      {/* <Heading color="red" size="lg" pt={21}>
        Uite cum poți economisi 150 RON
      </Heading> */}
      {/* <Heading size="md">
          Îți ofer ocazia să achiziționezi tot acest pachet, la un preț
          promoțional
        </Heading>
        <br />
        <br />
        <Heading size="md">
          Dar grăbește-te, propunerea este valabilă pentru doar 48 de ore!
        </Heading>
        <Image src={require('../../images/caiete.png')} />
        <Countdown
          date={new Date('2023', '02', '17', '23', '59')}
          renderer={renderer}
        /> */}
      <Heading size="lg">Sună prea bine ca să fie adevărat?</Heading>
      <Heading pt={30} size="xl">
        Ei bine, este real! Și știi de ce pot face acest lucru?
      </Heading>
      <Heading size="lg" mt={10}>
        Pentru că vreau să te ajut să iei nota pe care ți-o derești la bacul la
        informatică!
      </Heading>
      <Heading color="red" size="lg" pt={30}>
        Tot ce trebuie să faci este să te înscrii acum în programul
      </Heading>
      <Heading pt={10} size="lg">
        "Tot ce trebuie să știi pentru nota 10 la bacul la informatică"
      </Heading>
      <Heading color="green" size="xl" mt={30}>
        <br />
        La un preț pe care nu l-am mai oferit niciodată!
      </Heading>
      <Heading size="lg" py={10}>
        Am dublat perioada programului (de la 8 la 16 saptamani) si acest lucru
        înseamnă că:
      </Heading>
      <Box maxW="1000px" mx={7} px={7} textAlign={'left'}>
        <List spacing={3}>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei avea mai mult timp să aprofundezi teoria și să vizonezi lecțiile
            din platformă
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei putea rezolva în ritmul tău problemele și vei putea relua tot ce
            ai greșit
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei avea mai mult timp parte de mentorat din partea mea și a
            profului
          </ListItem>

          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei putea adresa mai multe întrebări, pentru a te debloca și pentru
            a progresa accelerat
          </ListItem>
        </List>
      </Box>
      <Heading pt={30} size="xl">
        Uite concret, cum se va desfășura programul
      </Heading>
      <Heading size="lg" mt={10}>
        Este primul curs din România care îți garantează nota dorită în doar
        câteva săptămâni!
      </Heading>
      <Heading size="lg" py={10}>
        Această călătorie presupune:
      </Heading>
      <Box maxW="1000px" mx={7} px={7} textAlign={'left'}>
        <List spacing={3}>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            vizionarea de lecții înregistrate în platforma online
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            sesiuni de Q&A la fiecare 2 săptâmâni, timp de 2 ore, pentru a
            discuta video-urile, exercițiile de la temă sau orice alte
            nelămuriri
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />o listă
            completă de exerciții, grupate pe categorii, pe care tu să le
            parcurgi și să le rezolvi pentru a înțelege mai bine noțiunile
            teoretice
          </ListItem>

          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            suport 24/7 într-un grup premiun de WhatsApp
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            materiale scrise după care să înveți mult mai ușor: primești GRATUIT
            pachetul de materiale din GIVEAWAY
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />2 mentori de
            neprețuit: <br />⟶ Alexandra 24/7 pe grupul de suport, telefon și
            convorbiri 1:1 și <br /> ⟶ Profu', unul dintre cei mai buni
            profesori de informatică din țară, pentru întâlnirile LIVE
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            multe alte bonusuri și surprize pe care le găsești mai jos ( și
            garantat, nu numai acelea!)
          </ListItem>
        </List>
      </Box>
      <Heading size="xl" pt={10}>
        Chiar daca intri acum, NU pierzi ce s-a făcut înainte!
      </Heading>
      <Heading pb={10} size="lg">
        Iată la ce să te aștepți după înscriere:
      </Heading>
      <Box maxW="1000px" mx={7} px={7} textAlign={'left'}>
        <List spacing={3}>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            te voi suna personal pentru a-ți confirma înscrierea și pentru a te
            felicita
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            vei avea acces instant la o platforma speciala unde vei putea
            viziona lectiile de teorie si unde vei piutea relua TOATE
            intalnirile LIVE care au avut deja loc cu proful de info
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            vei avea acces instant in grupul de WhatsApp
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            vei avea acces la MEGA pachetul cu caiete pe care l-am pus la bataie
            in GIVEAWAY
          </ListItem>

          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            vei avea acces la toate materialele din editiile trecute
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            vei avea acces la o platforma automata in care poti rezolva
            problemele de la subiectul III
          </ListItem>
        </List>
      </Box>
      <Heading color="red" size="xl" pt={10}>
        Ai parte numai de beneficii!
      </Heading>
      <Heading pb={10} size="lg">
        Primești GRATUIT caietele de la giveaway + 100 RON înapoi, dar mai ales
      </Heading>
      <Box maxW="1000px" mx={7} px={7} textAlign={'left'}>
        <List spacing={3}>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei beneficia de asistența mea pas cu pas pentru a învăța accelerat
            noțiunile de informatică
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei lucra direct și cu un profesor de infoirmatică cu peste 20 de
            ani de experiență, corector la examenul de bac, pentru a știi exact
            cum să redactezi fiecare exercițiu pentru punctaj maxim
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei urma o structură clară, care m-a ajutat atât pe mine, cât și pe
            sute de alți elevi
          </ListItem>

          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei relua de la 0, pas cu pas, toate noțiunile prevăzute în programa
            de bac
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Te vei concentra în fiecare săptămână pe un anumit capitol, pentru
            a-l înțelege așa cum trebuie
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei putea să îmi pui direct întrebări, oricând și fără frică
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei intra în contact cu alți elevi de clasa a 12-a și a 11-a care au
            același scop și obiectiv ca tine: nota 10 la bac la info{' '}
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei fi sigur că ai parcurs tot ce se poate și că ai făcut maximul
            posibil pentru a lua nota dorită la bacalaureat{' '}
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Îți va fi foarte clar cum să abordezi fiecare exercițiu din subiecte{' '}
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei fi motivat de mine, de Profu', dar și de ceilalți colegi de curs
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei putea discuta și dezbate diferite rezolvări cu ceilalți elevi
            înscriși
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei avea acces la un grup de suport 24/7 alături de alți elevi, de
            unde te vei putea alimenta cu energie și determinare
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Vei avea o atitudine mai relaxată și mai pozitivă față de acest
            examen
          </ListItem>
          <ListItem fontSize="xl">
            <ListIcon as={AiFillCheckCircle} color="green.500" />
            Voi fi alături de tine pe tot parcursul acestei călătorii și ne vom
            asigura ca ai ințeles toate aspectele teoretice
          </ListItem>
        </List>
      </Box>
      <Heading color="red" size="lg" pt={30}>
        Nu rata această oportunitate! Înscrie-te acum în programul
      </Heading>
      <Heading pt={10} size="lg">
        "Tot ce trebuie să știi pentru nota 10 la bacul la informatică"
      </Heading>
      <Heading color="green" size="xl" mt={10}>
        897 RON <strike>997 RON</strike> <strike>1497 RON</strike>
      </Heading>
      <br /> <br />
      <Heading color="red" size="xl" mt={30}>
        ATENȚIE! Oferta este limitată!
      </Heading>
      <Heading size="lg" pt={30}>
        Ai doar 48 de ore pentru a te alătura elevilor deja înscriși
      </Heading>
      <Countdown
        date={new Date('2023', '02', '17', '23', '59')}
        renderer={renderer}
      />
      {isCompleted ? (
        <Heading color="red" size="xl" mt={30}>
          Ai ratat oferta! Perioada promoțională a expirat!
        </Heading>
      ) : (
        <VStack>
          <a
            style={{ marginTop: '40px' }}
            href="https://www.alexandralaicu.ro/formular-inscriere-giveaway"
          >
            <Button size="lg" colorScheme={'green'} bg="green">
              Vreau în program!
            </Button>
          </a>
          <Text>
            <sup>*</sup>Pentru un plan personalizat de plata sau plata in rate
            scrie-mi un mesaj pe WhatsApp. Gasesti datele de contact in josul
            paginii, in footer.
          </Text>
        </VStack>
      )}
      <br /> <br />
      <Heading color="red" size="xl" mt={30}>
        Ce mai astepti?
      </Heading>
      <Heading maxW="2xl" textAlign="center" size="lg" mt={30}>
        Nota maxima la info este mai aproape decat crezi! Hai alaturi de noi,
        este ultima sesiune pentru bacul de anul acesta!
      </Heading>
    </VStack>
  );
}

export default SimpleOneVideoPage;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth';

import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAeV4kjhgAgoTTZf6q6RXw9buOfWCG2VC0',
  authDomain: 'alexandralaicu-curs.firebaseapp.com',
  projectId: 'alexandralaicu-curs',
  storageBucket: 'alexandralaicu-curs.appspot.com',
  messagingSenderId: '896967637172',
  appId: '1:896967637172:web:96b5b3f17f95077b5fffe1',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    console.log(email);
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
  }
};

// const registerWithEmailAndPassword = async (
//   firstName,
//   lastName,
//   email,
//   password
// ) => {
//   try {
//     const res = await createUserWithEmailAndPassword(auth, email, password);
//     const user = res.user;
//     await addDoc(collection(db, 'users'), {
//       uid: user.uid,
//       firstName,
//       lastName,
//       email,
//     });
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const sendPasswordReset = async email => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  app,
  auth,
  db,
  logInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordReset,
  logout,
};

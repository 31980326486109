import { db, auth, createUserWithEmailAndPassword } from './firebase';
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  setDoc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';

const usersCollectionRef = collection(db, 'users');

class UsersDataService {
  getAllUsers = () => {
    return getDocs(query(usersCollectionRef));
  };

  addUser = async (firstName, lastName, email, password) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      await setDoc(doc(db, 'users', user.uid), {
        firstName,
        lastName,
        email,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  getCurrentUserPersonalData = uid => {
    return getDoc(doc(usersCollectionRef, uid));
  };

  setCurrentUserLessonsStats = (uid, lessonsStats) => {
    const docRef = doc(usersCollectionRef, uid);
    return updateDoc(docRef, { lessonsStats });
  };

  setCurrentUserFullVideoStats = (uid, videoId, watchedPercent) => {
    const docRef = doc(usersCollectionRef, uid);
    videoId = videoId.replace('/', '|');
    return updateDoc(docRef, {
      [`fullVideoStats.${videoId}`]: watchedPercent,
    });
  };
}

export default new UsersDataService();

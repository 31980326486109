import {
  Box,
  Heading,
  useColorModeValue,
  Flex,
  Spacer,
  Button,
  Drawer,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  useDisclosure,
  DrawerBody,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
  HStack,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Center,
  VStack,
} from '@chakra-ui/react';

import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  lessonsDictionary,
  hasHomework,
  sessionModule,
  additionalInfo,
  customData,
} from '../../constants.js';
import { ColorModeSwitcher } from '../../ColorModeSwitcher.js';
import logo from '../../images/logo.png';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { IoMdExit } from 'react-icons/io';
import { auth, logout } from '../../services/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import useCheckIsLoggedIn from '../../hooks/checkIsLoggedIn';
import { useDispatch, useSelector } from 'react-redux';
import { clearState } from '../../features/checkCurrentUserSlice.js';
import { persistor } from '../../store';

export const Navbar = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isLoggedIn = useSelector(state => state.checkCurrentUser.isLoggedIn);
  const firstName = useSelector(state => state.checkCurrentUser.firstName);
  const lastName = useSelector(state => state.checkCurrentUser.lastName);

  const dispatch = useDispatch();

  const btnRef = React.useRef();
  return (
    <Flex
      w="100%"
      minWidth="100%"
      alignItems="center"
      gap="2"
      py="1rem"
      px="3rem"
      boxShadow={useColorModeValue('sm', 'sm-dark')}
      sx={{
        position: '-webkit-sticky',
        /* Safari */ position: 'sticky',
        top: '0',
        zIndex: 99,
        backdropFilter: 'blur(20px)',
      }}
      bg="whiteAlpha.100"
      borderBottomRadius={'15px'}
      borderBottomWidth={'2px'}
      borderBottomColor={'blackAlpha.100'}
      fontSize="xl"
    >
      <Link to="/">
        <HStack>
          <Image maxH={'50px'} borderRadius={'15px'} src={logo}></Image>
          <Heading size="md">
            Tot ce trebuie să știi pentru 10 la bac la informatică
          </Heading>
        </HStack>
      </Link>
      <Spacer />
      {isLoggedIn === true && (
        <>
          <Heading size={'md'}>Bine ai venit, {firstName}!</Heading>
          <Box width={7} />

          <Link to={`/cuprins-curs`}>
            <Button colorScheme="purple">Cuprins Curs</Button>
          </Link>

          <Button
            ref={btnRef}
            colorScheme="purple"
            variant={'outline'}
            onClick={onOpen}
          >
            Meniu
          </Button>
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
            closeOnOverlayClick="true"
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Meniu</DrawerHeader>

              <DrawerBody>
                <VStack>
                  <Avatar name={`${firstName} ${lastName}`}></Avatar>
                  <Heading size={'sm'}>{`${firstName} ${lastName}`}</Heading>
                </VStack>
                <Box height={10} />
                <Heading size={'md'}>Cuprins Curs</Heading>
                <Box height={3} />

                <Link to={'/cuprins-curs'} as={'button'} w={'100%'}>
                  <Text
                    borderRadius={'12px'}
                    px={3}
                    lineHeight={10}
                    onClick={onClose}
                    fontSize={18}
                    _hover={{ background: 'gray.200' }}
                  >
                    📋 Tabel Complet
                  </Text>
                </Link>
                <Link to={'/teorie'} as={'button'} w={'100%'}>
                  <Text
                    borderRadius={'12px'}
                    px={3}
                    lineHeight={10}
                    onClick={onClose}
                    fontSize={18}
                    _hover={{ background: 'gray.200' }}
                  >
                    📖 Lecții Teorie
                  </Text>
                </Link>
                <Link to={'/exercitii'} as={'button'} w={'100%'}>
                  <Text
                    borderRadius={'12px'}
                    px={3}
                    lineHeight={10}
                    onClick={onClose}
                    fontSize={18}
                    _hover={{ background: 'gray.200' }}
                  >
                    ✏️ Exerciții Teme
                  </Text>
                </Link>
                <Link to={'/sesiuni-live'} as={'button'} w={'100%'}>
                  <Text
                    borderRadius={'12px'}
                    px={3}
                    lineHeight={10}
                    onClick={onClose}
                    fontSize={18}
                    _hover={{ background: 'gray.200' }}
                  >
                    🎤 Sesiuni Live
                  </Text>
                </Link>
                <Box height={10} />
                <Heading size={'md'}>Interacționează</Heading>
                <Box height={3} />
                <Link
                  to={'https://chat.whatsapp.com/I3laa5fBeMEJcYZ5F4vFeB'}
                  as={'button'}
                  w={'100%'}
                >
                  <Text
                    borderRadius={'12px'}
                    px={3}
                    lineHeight={10}
                    onClick={onClose}
                    fontSize={18}
                    _hover={{ background: 'gray.200' }}
                  >
                    ❓ Pune o întrebare
                  </Text>
                </Link>
                <Link
                  to={
                    'https://us05web.zoom.us/j/97380608145?pwd=jzrTX3upPzZIMvb92tC8XGhlaviowO.1'
                  }
                  as={'button'}
                  w={'100%'}
                >
                  <Text
                    borderRadius={'12px'}
                    px={3}
                    lineHeight={10}
                    onClick={onClose}
                    fontSize={18}
                    _hover={{ background: 'gray.200' }}
                  >
                    🔌 Conectează-te pe Zoom
                  </Text>
                </Link>
                {/* <Accordion allowToggle="true">
                  {Object.entries(lessonsDictionary).map(([k, v], i) => {
                    return (
                      <AccordionItem key={k}>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            <Heading size="sm">{v['title']}</Heading>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                          <Link
                            to={`/module-${k}/1`}
                            as={'button'}
                            w={'100%'}
                            onClick={onClose}
                          >
                            <Text
                              borderRadius={'12px'}
                              px={3}
                              lineHeight={10}
                              _hover={{ background: 'gray.200' }}
                            >
                              Videouri Teorie
                            </Text>
                          </Link>

                          {hasHomework[i] !== false && (
                            <Link
                              to={`/exercitii/${hasHomework[i]}`}
                              as={'button'}
                              w={'100%'}
                              onClick={onClose}
                            >
                              <Text
                                borderRadius={'12px'}
                                px={3}
                                lineHeight={10}
                                _hover={{ background: 'gray.200' }}
                              >
                                Exerciții Temă
                              </Text>
                            </Link>
                          )}
                          {v['liveSession'] !== undefined &&
                            v['liveSession'].map((e, i) => (
                              <Link
                                to={`sesiuni-live/${v['liveSession'][i]}`}
                                as={'button'}
                                w={'100%'}
                                onClick={onClose}
                              >
                                <Text
                                  borderRadius={'12px'}
                                  px={3}
                                  lineHeight={10}
                                  _hover={{ background: 'gray.200' }}
                                >
                                  Video Sesiune LIVE #{e}
                                </Text>
                              </Link>
                            ))}
                          {additionalInfo[i] !== false &&
                            additionalInfo[i].map((e, i) => {
                              return (
                                <Link
                                  to={e['link']}
                                  as={'button'}
                                  w={'100%'}
                                  onClick={onClose}
                                >
                                  <Text
                                    borderRadius={'12px'}
                                    px={3}
                                    lineHeight={10}
                                    _hover={{ background: 'gray.200' }}
                                  >
                                    {e['name']}
                                  </Text>
                                </Link>
                              );
                            })}
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}

                  {Object.entries(customData).map(([k, v], index) => {
                    console.log(k, v);
                    return (
                      <AccordionItem key={`customData-${index}`}>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            <Heading size="sm">{v['chapter']}</Heading>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                          {v.videos.map((video, i) => {
                            return (
                              <Link
                                to={`/${k}/${i + 1}`}
                                as={'button'}
                                w={'100%'}
                                onClick={onClose}
                              >
                                <Text
                                  borderRadius={'12px'}
                                  px={3}
                                  lineHeight={10}
                                  _hover={{ background: 'gray.200' }}
                                >
                                  Partea #{i + 1}
                                </Text>
                              </Link>
                            );
                          })}
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion> */}
                <Box height={10} />
                <Button
                  margin={'auto'}
                  textAlign={'center'}
                  size="sm"
                  text
                  fontSize="md"
                  variant="outline"
                  colorScheme={'red'}
                  color="red.600"
                  width="100%"
                  onClick={() => {
                    persistor.pause();
                    persistor.purge();
                    localStorage.clear();
                    sessionStorage.clear();
                    dispatch(clearState());
                    logout();
                  }}
                >
                  <HStack>
                    <IoMdExit />
                    <Text size={'sm'}>Ieși din cont</Text>
                  </HStack>
                </Button>
              </DrawerBody>

              <DrawerFooter textAlign={'center'} size={'sm'}>
                Tot ce trebuie să știi pentru 10 la bac la informatică
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </Flex>
  );
};

import { auth, logout } from '../services/firebase';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUserPersonalData } from '../features/checkCurrentUserSlice';
import UsersDataService from '../services/userDatabase';

const useGetUserPersonalData = uid => {
  const firstName = useSelector(state => state.checkCurrentUser.firstName);
  const lastName = useSelector(state => state.checkCurrentUser.lastName);
  const email = useSelector(state => state.checkCurrentUser.email);
  const dispatch = useDispatch();

  const getUserPersonalData = async uid => {
    try {
      const user = await UsersDataService.getCurrentUserPersonalData(uid);
      const userData = user.data();
      console.log(userData);
      dispatch(
        setCurrentUserPersonalData({
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          lessonsStats:
            userData.lessonsStats !== undefined
              ? userData.lessonsStats
              : { lastLesson: 1, lastModule: 'module-1' },
          fullVideoStats:
            userData.fullVideoStats !== undefined
              ? userData.fullVideoStats
              : {},
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (firstName === '') {
      getUserPersonalData(uid);
    } else {
      console.log('restoring...');
    }
  }, []);

  return [firstName, lastName, email];
};

export default useGetUserPersonalData;

import {
  Container,
  Heading,
  Box,
  Center,
  VStack,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Button,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import ReactPlayer from 'react-player';
import {
  urlArray,
  nameArray,
  modules,
  lessonsDictionary,
} from '../../constants.js';
import React, { useEffect, useState } from 'react';
import { useLocation, Navigate, useNavigate, Link } from 'react-router-dom';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import './player.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentUserlessonsStats,
  setCurrentUserOverallVideoStats,
} from '../../features/checkCurrentUserSlice.js';
import UsersDataService from '../../services/userDatabase.js';
import BackToDashboard from '../../components/BackToDashboard/BackToDashboard.jsx';
import BackTo from '../../components/BackTo/BackTo';

function VideoDetail(props, { location }) {
  const urlLocation = useLocation(); // React Hook

  const [lessonURL, setLessonURL] = useState('');
  const [watchedPercent, setWatchedPercent] = useState(0);

  const number = urlLocation.pathname.trim().split('/')[2];
  const module = urlLocation.pathname.trim().split('/')[1];

  const uid = useSelector(state => state.checkCurrentUser.userId);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setStats = async uid => {
    dispatch(
      setCurrentUserlessonsStats({
        lastLesson: number,
        lastModule: `module-${parseInt(module.split('-')[1])}`,
      })
    );

    console.log('setCurrentUserlessonsStats in VideoDetail.jsx');
    await UsersDataService.setCurrentUserLessonsStats(uid, {
      lastLesson: number,
      lastModule: `module-${parseInt(module.split('-')[1])}`,
    });
  };

  const setVideoFullOverview = async uid => {
    dispatch(
      setCurrentUserOverallVideoStats({
        percent: watchedPercent,
        videoId: `${module}/${number}`,
      })
    );
    await UsersDataService.setCurrentUserFullVideoStats(
      uid,
      `${module}/${number}`,
      watchedPercent
    );
  };

  useEffect(() => {
    console.log(`module-${parseInt(module.split('-')[1])}`, number);
    setLessonURL(
      `https://curs-info-teorie.s3.eu-central-1.amazonaws.com/${
        lessonsDictionary[parseInt(module.split('-')[1])]['urls'][number - 1]
      }`
    );
  }, [number, module]);

  useEffect(() => {
    if (watchedPercent === 100) {
      console.log('setting user stats');
      setStats(uid);
      setVideoFullOverview(uid);
    }
  }, [watchedPercent]);

  // checking if the URL is valid
  if (
    module.split('-').length === 2 &&
    module.split('-')[0] === 'module' &&
    parseInt(module.split('-')[1]) > 0 &&
    parseInt(module.split('-')[1]) <= 11 &&
    parseInt(number) <=
      lessonsDictionary[module.split('module-')[1]]['urls'].length &&
    number > 0
  ) {
    console.log('yes');
  } else {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Center>
        <VStack>
          <Box
            m={7}
            width={{ base: '95%', md: '90%', lg: '75%' }}
            textAlign={'left'}
          >
            <BackTo link="/teorie" text="Listă Module" />
          </Box>
          <Heading size="lg" mt={10}>
            {'Lecția #' +
              parseInt(number) +
              ': ' +
              lessonsDictionary[parseInt(module.split('-')[1])]['lessons'][
                number - 1
              ]}
          </Heading>
          <Heading size="md">{'Modulul ' + module.split('-')[1]}</Heading>
          <Center w={'100%'}>
            <Button
              isDisabled={number <= 1}
              pos={{ base: 'absolute', md: 'absolute', lg: 'relative' }}
              left={0}
              zIndex={1}
              variant={'ghost'}
              onClick={() => {
                navigate(
                  `/module-${module.split('-')[1]}/${parseInt(number) - 1}`
                );
                setLessonURL(
                  `https://curs-info-teorie.s3.eu-central-1.amazonaws.com/${
                    lessonsDictionary[parseInt(module.split('-')[1])]['urls'][
                      parseInt(number) - 1
                    ]
                  }`
                );
              }}
              ml={0}
              marginInlineStart={0}
              marginInlineEnd={0}
            >
              <FaChevronCircleLeft />
            </Button>

            <Box
              m={7}
              width={{ base: '95%', md: '90%', lg: '75%' }}
              borderRadius="15px"
              bg={'gray.200'}
              textAlign={'center'}
            >
              <ReactPlayer
                className="react-player"
                width="100%"
                height="100%"
                key={lessonURL}
                url={lessonURL}
                preload="metadata"
                controls
                onProgress={videoProgress => {
                  setWatchedPercent(videoProgress.played * 100);
                }}
              />
            </Box>

            <Button
              isDisabled={
                number >=
                lessonsDictionary[parseInt(module.split('-')[1])]['urls'].length
              }
              pos={{ base: 'absolute', md: 'absolute', lg: 'relative' }}
              right={0}
              zIndex={1}
              variant={'ghost'}
              onClick={() => {
                navigate(
                  `/module-${module.split('-')[1]}/${parseInt(number) + 1}`
                );
                setLessonURL(
                  `https://curs-info-teorie.s3.eu-central-1.amazonaws.com/${
                    lessonsDictionary[parseInt(module.split('-')[1])]['urls'][
                      parseInt(number) + 1
                    ]
                  }`
                );
              }}
              m={0}
            >
              <FaChevronCircleRight />
            </Button>
          </Center>

          <Accordion
            pb={7}
            allowToggle="true"
            width={{ base: '95%', md: '90%', lg: '75%' }}
            defaultIndex={parseInt(module.split('-')[1]) - 1}
          >
            {Object.entries(lessonsDictionary).map(([k, v]) => {
              return (
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading size="md">{v['title']}</Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {v['lessons'].map((e, i) => {
                      return (
                        <Box
                          px={3}
                          lineHeight={7}
                          borderRadius={'12px'}
                          _hover={{ background: 'gray.200' }}
                        >
                          <Link
                            to={`/module-${k}/${i + 1}`}
                            as={'button'}
                            w={'100%'}
                          >
                            <HStack>
                              <Text
                                fontWeight={
                                  parseInt(number) === parseInt(i + 1) &&
                                  k === module.split('-')[1]
                                    ? 'bold'
                                    : 'normal'
                                }
                              >
                                {i + 1}. {e}
                              </Text>

                              <Spacer />
                              <CheckIcon
                                color={
                                  parseInt(number) === parseInt(i + 1) &&
                                  k === module.split('-')[1] &&
                                  watchedPercent > 90
                                    ? 'green.400'
                                    : 'gray.100'
                                }
                              />
                            </HStack>
                          </Link>
                        </Box>
                      );
                    })}
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </VStack>
      </Center>
    </>
  );
}

export default VideoDetail;

import React from 'react';
import { Text, Box, Center, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

function BackToDashboard() {
  return (
    <Box
      textAlign="left"
      width={{ base: '95%', md: '90%', lg: '75%', xl: '60%' }}
    >
      <Link to="/">
        <Button mt={10} size="lg" colorScheme="purple" variant="outline">
          <FaArrowLeft /> <Text ml={3}>Dashboard</Text>
        </Button>
      </Link>
    </Box>
  );
}

export default BackToDashboard;

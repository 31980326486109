import React from 'react';
import BackToDashboard from '../../components/BackToDashboard/BackToDashboard';
import {
  Center,
  VStack,
  Heading,
  Card,
  Image,
  Stack,
  Text,
  CardBody,
  Button,
  CardFooter,
  Container,
} from '@chakra-ui/react';
import { lessonsDictionary, thumbnails } from '../../constants';
import { Link, useNavigate } from 'react-router-dom';
import BackTo from '../../components/BackTo/BackTo';
import HorizontalImageCard from '../../components/HorizontalImageCard/HorizontalImageCard';

function TheoryIndex() {
  return (
    <>
      <Center>
        <VStack>
          <Container maxW="container.xl">
            {' '}
            <BackToDashboard />
          </Container>
          <Heading size="xl" mt={10}>
            Module Teorie
          </Heading>
          <Container maxW="container.xl">
            {Object.entries(lessonsDictionary).map(([k, v], i) => {
              console.log(thumbnails.length);
              return (
                <HorizontalImageCard
                  thumbnail={thumbnails[thumbnails.length - 1 - i]}
                  title={v.title}
                  description={v.description}
                  footer={`Temele se încarcă pe drive.`}
                  link={`/module-${i + 1}/1`}
                  buttonText="Vezi Video-uri"
                />
              );
            })}
          </Container>
        </VStack>
      </Center>
    </>
  );
}

export default TheoryIndex;

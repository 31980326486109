import React from 'react';
import {
  useColorMode,
  useColorModeValue,
  Text,
  Button,
  HStack,
} from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';

export const ColorModeSwitcher = props => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <Button
      textAlign={'left'}
      size="md"
      fontSize="lg"
      aria-label={`Switch to ${text} mode`}
      variant="unstyled"
      color="current"
      onClick={toggleColorMode}
      width="100%"
      {...props}
    >
      <HStack>
        <SwitchIcon />
        <Text>Schimbă tema</Text>
      </HStack>
    </Button>
  );
};

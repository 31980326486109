import React from 'react';
import BackToDashboard from '../../components/BackToDashboard/BackToDashboard';
import { Center, VStack, Heading, Container } from '@chakra-ui/react';
import {
  lessonsDictionary,
  thumbnails,
  hasHomework,
  exercisesDictionary,
} from '../../constants';
import HorizontalImageCard from '../../components/HorizontalImageCard/HorizontalImageCard';

function HomeworkIndex() {
  const exercisesNumber = [
    'Pseudocod - 100',
    'C/C++ - 100',
    'Tablouri - 39, Siruri - 59, Structuri - 45',
    'Backtracking - 95, Recursivitate - 96',
    'Grafuri - 114, Arbori - 63',
    'S3.1 - 86, S3.2 - 114, S3.3 - 97',
  ];
  return (
    <>
      <Center>
        <VStack>
          <Container maxW="container.xl">
            <BackToDashboard />
          </Container>
          <Heading size="xl" mt={10}>
            Capitole Teme
          </Heading>
          <Container maxW="container.xl">
            {Object.entries(lessonsDictionary).map(([k, v], i) => {
              console.log(hasHomework[i]);

              return (
                hasHomework[i] && (
                  <HorizontalImageCard
                    thumbnail={thumbnails[thumbnails.length - 1 - i]}
                    title={`Temă ${exercisesDictionary[hasHomework[i]].join(
                      ', '
                    )}`}
                    footer={`Număr Exerciții: ${
                      exercisesNumber[hasHomework[i] - 1]
                    }`}
                    link={`/exercitii/${hasHomework[i]}`}
                    buttonText="Mergi la Exerciții"
                  />
                )
              );
            })}
          </Container>
        </VStack>
      </Center>
    </>
  );
}

export default HomeworkIndex;

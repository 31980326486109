import {
  Container,
  Heading,
  Box,
  Center,
  VStack,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Button,
  HStack,
  Spacer,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';
import { CheckIcon, CheckCircleIcon } from '@chakra-ui/icons';
import ReactPlayer from 'react-player';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, Navigate, useNavigate, Link } from 'react-router-dom';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';

import './player.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  sessionModule,
  liveSessionssHeld,
  sessionDates,
} from '../../constants';
import BackToDashboard from '../../components/BackToDashboard/BackToDashboard';
import BackTo from '../../components/BackTo/BackTo';

function LiveSession(props, { location }) {
  const currentSessionStartDate = new Date('2024', '00', '20', 14, 0, 0);
  const auxUrrentSessionStartDate = new Date('2024', '00', '20', 14, 0, 0);
  const urlLocation = useLocation(); // React Hook
  const urlNo = parseInt(urlLocation.pathname.trim().split('/')[2]);

  console.log(urlNo);

  const [sessionNo, setSessionNo] = useState(urlNo);
  const [sessionUrl, setSessionUrl] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    'in use effect';
    setSessionNo(urlNo);

    let year = sessionDates[urlNo - 1].getFullYear();
    let monthIndex = String(sessionDates[urlNo - 1].getMonth() + 1);

    let day = String(sessionDates[urlNo - 1].getDate());

    setSessionUrl(
      `https://curs-info-teorie.s3.eu-central-1.amazonaws.com/SesiuniLive/G4/GMT${year}${monthIndex.padStart(
        2,
        '0'
      )}${day.padStart(2, '0')}.mp4`
    );
  }, [sessionNo, urlNo]);

  // checking if the URL is valid
  // checking if the URL is valid
  if (parseInt(sessionNo) > 0 && parseInt(sessionNo) <= liveSessionssHeld) {
    console.log('yes');
  } else {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Center>
        <VStack>
          <Box
            m={7}
            width={{ base: '95%', md: '90%', lg: '75%' }}
            textAlign={'left'}
          >
            <BackTo link="/sesiuni-live" text="Listă Sesiuni Live" />
          </Box>
          <Heading size="lg" mt={10}>
            {'Sesiunea Live Q&A #' + parseInt(sessionNo)}
          </Heading>
          <Center w={'100%'}>
            <Button
              isDisabled={sessionNo <= 1}
              pos={{ base: 'absolute', md: 'absolute', lg: 'relative' }}
              left={0}
              zIndex={1}
              variant={'ghost'}
              onClick={() => {
                navigate(`/sesiuni-live/${parseInt(sessionNo) - 1}`);
                setSessionNo(sessionNo - 1);
              }}
              ml={0}
              marginInlineStart={0}
              marginInlineEnd={0}
            >
              <FaChevronCircleLeft />
            </Button>
            <Box
              m={7}
              width={{ base: '95%', md: '90%', lg: '75%' }}
              borderRadius="15px"
              bg={'gray.200'}
              textAlign={'center'}
            >
              <ReactPlayer
                className="react-player"
                width="100%"
                height="100%"
                key={sessionUrl}
                url={sessionUrl}
                preload="metadata"
                controls
              />
            </Box>
            <Button
              isDisabled={sessionNo >= liveSessionssHeld}
              pos={{ base: 'absolute', md: 'absolute', lg: 'relative' }}
              right={0}
              zIndex={1}
              variant={'ghost'}
              onClick={() => {
                navigate(`/sesiuni-live/${parseInt(sessionNo) + 1}`);
                setSessionNo(sessionNo + 1);
              }}
              m={0}
            >
              <FaChevronCircleRight />
            </Button>
          </Center>
          <Heading pb="5" size="lg">
            Cuprins Întâlniri LIVE
          </Heading>
          <List width={{ base: '95%', md: '90%', lg: '75%' }} spacing={3}>
            {sessionModule.map((e, i) => {
              return (
                <ListItem>
                  <Box px={3} lineHeight={7} borderRadius={'12px'}>
                    <Button
                      colorScheme={'purple'}
                      bg={
                        parseInt(sessionNo) === parseInt(i + 1)
                          ? 'purple.700'
                          : 'purple.500'
                      }
                      isDisabled={i + 1 > liveSessionssHeld}
                      w={'100%'}
                      onClick={() => {
                        navigate(`/sesiuni-live/${i + 1}`);
                        setSessionNo(i + 1);
                      }}
                      _hover={{ background: 'purple.300' }}
                    >
                      <HStack>
                        <Text
                          fontWeight={
                            parseInt(sessionNo) === parseInt(i + 1)
                              ? 'bold'
                              : 'normal'
                          }
                        >
                          Sesiunea #{i + 1}. {e}
                        </Text>

                        <Spacer />
                        <Text
                          fontWeight={
                            parseInt(sessionNo) === parseInt(i + 1)
                              ? 'bold'
                              : 'normal'
                          }
                        >
                          {new Date(sessionDates[i]).toLocaleDateString('ro')}
                        </Text>
                      </HStack>
                    </Button>
                  </Box>
                </ListItem>
              );
            })}
            /
          </List>
        </VStack>
      </Center>
    </>
  );
}

export default LiveSession;

export const urlArray = [
  'Modulul1/11.Intro curs.mov',
  'Modulul1/12.Algoritmul.mov',
  'Modulul1/13.Date.mov',
  'Modulul1/14.Constante.Variabile..mov',
  'Modulul1/15.Pseudocodul.mov',
  'Modulul1/16Expresii.mov',
  'Modulul1/17Operatia de atribuire 1.mov',
  'Modulul1/18Exemple atribuiri.mov',
  'Modulul1/19Challenge1.mov',
  'Modulul1/110a=b b=a.mov',
  'Modulul1/111Operatia in:out.mov',
  'Modulul1/112Borcan si Challenge 2.mov',
  'Modulul1/113Operatia de decizie.mov',
  'Modulul1/114Programarea structurata.mov',
  'Modulul1/115Exemplu liniar. Structura alternativa..mov',
  'Modulul1/116Paradigma.mov',
  'Modulul1/117Structura repetitiva.mov',
  'Modulul1/118Transformare while - for. Cifrele unui numar..mov',
  'Modulul1/119Continuare structuri repetitive.mov',
  'Modulul1/120Suma numerelor.mov',
  'Modulul2/21Structura generala.mov',
  'Modulul2/22.Functia main.mov',
  'Modulul2/23.Sintaxa.mov',
  'Modulul2/24.Tipuri de date.mov',
  'Modulul2/25.Variabile si Constante. Op aritmetici..mov',
  'Modulul2/26.Operatori logici.mov',
  'Modulul2/27.Operatori de comparatie si egalitate..mov',
  'Modulul2/28.Challenge 1. Expresii..mov',
  'Modulul2/29.Challenge 2 redone.mov',
  'Modulul2/210. Operatia de intrare si iesire.mov',
  'Modulul2/211.Instructiuni.mov',
  'Modulul2/212.Instructiunea if.mov',
  'Modulul2/213. Instructiunea switch.mov',
  'Modulul2/214.Instructiunea while.mov',
  'Modulul2/215.Do while. For..mov',
  'Modulul3/1. Intro.mov',
  'Modulul3/2.Cum cream o functie.mov',
  'Modulul3/3.Prototipul.mov',
  'Modulul3/Variabile locale si globale.mov',
  'Modulul3/5.Exemplu.mov',
  'Modulul3/6.Transmiterea parametrilor.mov',
  'Modulul4/1. Intro. Vectori.mov',
  'Modulul4/2.Exemplu cod.mov',
  'Modulul4/3. Initializarea vectorului.mov',
  'Modulul4/4. Matrice.mov',
  'Modulul4/5. Continuare + Matricea patratica.mov',
  'Modulul4/6. Siruri de caractere.mov',
  'Modulul4/7. Functii predefinite.mov',
  'Modulul4/8. Exemplu functii predefinite.mov',
  'Modulul4/9.Sirurile de caractere si pointerii.mov',
  'Modulul4/10.Pointeri.Challenge..mov',
  'Modulul4/11.Strtok.mov',
  'Modulul4/12.Tipul Inregistrare.mov',
  'Modulul4/13. Accesare campuri. Challenge..mov',
  'Modulul5/1.Intro fisiere.mov',
  'Modulul5/2.Operatii cu fisiere.mov',
  'Modulul5/3.Exemplu.mov',
  'Modulul6/1.Intro.mov',
  'Modulul6/2.Cifrele unui numar.mov',
  'Modulul6/3.Exemplu cifrele unui numar.mov',
  'Modulul6/4.Divizori.Numere prime..mov',
  'Modulul6/5.Exemplu.mov',
  'Modulul6/6.CMMDC.mov',
  'Modulul6/61.CMMDC Exemplu.mov',
  'Modulul6/7.Fibonacci. Sume cu termen dat..mov',
  'Modulul6/8.Fibo exemplu.mov',
  'Modulul6/9.Minim.Maxim.mov',
  'Modulul6/10.Exemplu minim si maxim.mov',
  'Modulul6/11.Sortarea.Bubble Sort..mov',
  'Modulul6/12.Insertion sort.mov',
  'Modulul6/13.Selection sort.mov',
  'Modulul6/14.Metoda numararii.mov',
  'Modulul6/15.Interclasarea a doi vectori.mov',
  'Modulul6/16.Metode de cautare.Cautarea secventiala.mov',
  'Modulul6/17.Cautarea Binara.mov',
  'Modulul6/18.Complexitatea.mov',
  'Modulul6/19.Tipuri de complexitate.mov',
  'Modulul7/1.Intro recursivitate.mov',
  'Modulul7/2.Stiva.mov',
  'Modulul7/3.Fibo.mov',
  'Modulul7/4.Fibo exemplu.mov',
  'Modulul7/5.Factorial recursiv.mov',
  'Modulul8/1.Intro.mov',
  'Modulul8/2.Teorie Backtracking.mov',
  'Modulul8/3.Exemplu bac.mov',
  'Modulul8/4.Permutari.Combinari.mov',
  'Modulul8/5.Aranjamente, submultimi si produs cartezian.mov',
  'Modulul9/1.Intro.mov',
  'Modulul9/2.Terminologie.mov',
  'Modulul9/3.Teorema grad.mov',
  'Modulul9/4.Lanturi.Cicluri..mov',
  'Modulul9/5.Graf partial,complementar,subgraf.mov',
  'Modulul9/6.Conexitatea.mov',
  'Modulul9/7.Graf hamiltonian si eulerian.mov',
  'Modulul9/8.Metode de reprezentare.mov',
  'Modulul9/9.Grafuri orientate.mov',
  'Modulul9/10.Graf partial.Subgraf..mov',
  'Modulul9/11.Conexitate.Grafuri hamiltoniene,euleriene.mov',
  'Modulul9/12.Arbori.mov',
  'Modulul9/13.Metode de reprezentare.mov',
  'Modulul10/1.Intro bac.mov',
  'Modulul10/2.11.mov',
  'Modulul10/3.12.mov',
  'Modulul10/4.13.mov',
  'Modulul10/5.14.mov',
  'Modulul10/6.15.mov',
  'Modulul10/7.Intro 2.mov',
  'Modulul10/8.2.1a.mov',
  'Modulul10/9.21b.mov',
  'Modulul10/10.21c.mov',
  'Modulul10/11.21d.mov',
  'Modulul10/12.22 full.mov',
  'Modulul10/13.23.mov',
  'Modulul10/14.Intro 3.mov',
  'Modulul10/15.31 part 1.mov',
  'Modulul10/16. 31 part 2.mov',
  'Modulul10/17.31 part 3.mov',
  'Modulul10/18.32 part 1.mov',
  'Modulul10/19.32 part 2.mov',
  'Modulul10/20. 32 part 3.mov',
  'Modulul10/21.33 part 1.mov',
  'Modulul10/22. .mov',
  'Modulul10/23.Citire fisier.mov',
  'Install/Ghid.mov',
  'Install/Bac Info.mov',
  'Install/2.Windows 2.mov',
  'Install/3. Mac .mov',
];

export const nameArray = [
  'Introducere',
  'Algoritmul',
  'Datele',
  'Variabile si constante',
  'Pseudocodul',
  'Expresii',
  'Operatia de atribuire',
  'Exemple atribuiri',
  'Challenge1',
  'a=b sau b=a?',
  'Operatia de citire si de scriere',
  'Challenge 2',
  'Operatia de decizie',
  'Programarea structurata',
  'Structura liniara. Structura alternativa.',
  'Paradigma programarii structurate',
  'Structura repetitiva 1',
  'Transformare for in while. Exemplu prelucrare cifrele unui numar.',
  'Structura repetitiva 2',
  'Suma numerelor naturale de la 1 la n si transformare structura repetitiva.',
  'Structura generala a unui program C++',
  'Functia main',
  'Sintaxa. Semantica. Vocabular',
  'Tipuri de date',
  'Variabile si constante. Operatori aritmetici',
  'Operatori logici',
  'Operatori de comparatie si egalitate',
  'Challenge 1. Expresii',
  'Challenge 2',
  'Operatia de intrare si operatia de iesire',
  'Instructiuni',
  'Instructiunea IF',
  'Instructiunea SWITCH',
  'Instructiunea WHILE',
  'Instructiunea DO WHILE si instructiunea FOR',
  'Introducere in subprograme',
  'Cum cream o functie?',
  'Prototipul',
  'Variabile locale si globale',
  'Domeniul de vizibilitate',
  'Transmiterea parametrilor',
  'Introducere. Vectori.',
  'Exemplu cod',
  'Initializarea vectorului',
  'Matricea',
  'Continuare matrice. Matricea patratica.',
  'Siruri de caractere',
  'Functii predefinite pentru sirurile de caractere',
  'Challenge pointeri',
  'Exemplu functii predefinite',
  'Siruri de caractere si pointeri',
  'Functia strtok',
  'Tipul inregistrare',
  'Accesarea campurilor. Challenge',
  'Introducere fisiere',
  'Operatii cu fisiere',
  'Exemplu',
  'Despre algoritmii elementari',
  'Cifrele unui numar',
  'Program cifrele unui numar',
  'Divizori. Numere prime',
  'Program divizori si numere prime',
  'CMMDC',
  'Program CMMDC',
  'Fibonacci. Sume cu termen general dat',
  'Program Fibonacci',
  'Minim. Maxim.',
  'Program minim si maxim.',
  'Sortari. Bubble sort.',
  '[BONUS] Insertion sort.',
  '[BONUS] Selection sort',
  '[BONUS] Sortarea prin metoda numararii',
  'Interclasarea a doi vectori',
  'Metode de cautare. Cautarea secventiala',
  'Cautarea binara',
  'Complexitatea',
  'Tipuri de complexitate',
  'Introducere recursivitate',
  'Stiva',
  'Fibonacci recursiv',
  'Program Fibonacci recursiv',
  'Factorial recursiv. Program factorial recursiv.',
  'Introducere backtracking',
  'Teorie backtracking',
  'Exemplu',
  'Permutari. Combinari',
  'Aranjamente. Submultimi. Produs cartezian',
  'Introducere grafuri',
  'Grafuri neorientate. Extremitati. Noduri. Muchii. Incidenta. Grad',
  'Teorema grad',
  'Lanturi. Cicluri',
  'Graf partial. Subgraf. Graf complementar.',
  'Conexitatea',
  'Graf hamiltonian si eulerian',
  'Metode de reprezentare',
  'Drum. Circuit. Graf partial. Subgraf',
  'Grafuri orientate. Extremitati. Noduri. Arce. Incidenta. Grade',
  'Conexitate. Tare conexitate. Graf hamiltonian si eulerian',
  'Arbori. Terminologie',
  'Metode de reprezentare',
  'Introducere',
  'Subiectul 1 exercitiul 1',
  'Subiectul 1 exercitiul 2',
  'Subiectul 1 exercitiul 3',
  'Subiectul 1 exercitiul 4',
  'Subiectul 1 exercitiul 5',
  'Introducere subiect 2',
  'Subiectul 2 exercitiul 1a',
  'Subiectul 2 exercitiul 1b',
  'Subiectul 2 exercitiul 1c',
  'Subiectul 2 exercitiul 1d',
  'Subiectul 2 exercitiul 2',
  'Subiectul 2 exercitiul 3',
  'Introducere subiect 3',
  'Subiectul 3 exercitiul 1 partea 1',
  'Subiectul 3 exercitiul 1 partea 2',
  'Subiectul 3 exercitiul 1 partea 3',
  'Subiectul 3 exercitiul 2 partea 1',
  'Subiectul 3 exercitiul 2 partea 2',
  'Subiectul 3 exercitiul 2 partea 3',
  'Subiectul 3 exercitiul 3 partea 1',
  'Subiectul 3 exercitiul 3 partea 2',
  'Subiectul 3 exercitiul 3 citire din fisier (video)',
  'Introducere',
  'Windows: descarcare Code::Blocks',
  'Windows: descarcare Code::Blocks',
  'MacOs: descarcare si instalare Xcode',
];

export const modules = [
  'Algoritmul. Notiuni Fundamentale.',
  'Introducere in limbajul de programare C++',
  'Subprograme',
  'Tipuri structurate de date',
  'Lucrul cu fisiere',
  'Algoritmi elementari. Sortari si cautari. Complexitate.',
  'Recursivitate',
  'Backtracking. Combinatorica',
  'Grafuri. Arbori',
  'Rezolvare integrala subiect BAC',
  'Ghid de instalare programe',
];

export const exercisesDictionary = {
  1: ['Pseudocod'],
  2: ['C/C++'],
  3: ['Tablouri', 'Șiruri de Caractere', 'Structuri'],
  4: ['Backtracking', 'Recursivitate'],
  5: ['Grafuri', 'Arbori'],
  6: ['Subiectul 3.1', 'Subiectul 3.2', 'Subiectul 3.3'],
};

export const hasHomework = [
  1,
  2,
  6,
  3,
  6,
  6,
  5,
  4,
  4,
  false,
  false,
  false,
  false,
];

export const additionalInfo = [
  [
    {
      link: 'https://drive.google.com/file/d/1q7V3LsE3p7VE_WeqOb3FPx32TBv-UIPW/view?usp=drive_link',
      name: 'Material Structuri Repetitive',
    },
  ],
  false,
  false,
  [
    {
      link: 'https://drive.google.com/file/d/1hUiaC9aYK0OlZwG95CCwKsrwEGkfvifx/view?usp=drive_link',
      name: 'Material Tablouri',
    },
  ],
  false,
  [
    {
      link: 'https://drive.google.com/drive/folders/1vRVTLcto4MqFIx6qgf0x_ElzTJvXg17t?usp=sharing',
      name: 'Caiet Studiu Algoritmi Elementari',
    },
  ],
  false,
  false,

  [
    {
      link: '/grafuri-materiale-aditionale/1',
      name: 'Materiale Adiționale Grafuri și Arbori',
    },
  ],
  false,
  false,
];

export const homeworkTitle = [
  'Algoritmul. Notiuni Fundamentale.',
  'Introducere in limbajul de programare C++',
  'Tipuri structurate de date',
  'Recursivitate. Backtracking. Combinatorica',
  'Grafuri. Arbori',
  'Subiectul III',
];

export const thumbnails = [
  'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Thumbnails/chapter_1.png',
  'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Thumbnails/chapter_2.png',
  'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Thumbnails/chapter_5.png',
  'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Thumbnails/chapter_4.png',
  'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Thumbnails/chapter_3.png',

  'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Thumbnails/chapter_6.png',
  'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Thumbnails/chapter_7.png',
  'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Thumbnails/chapter_8.png',
  'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Thumbnails/chapter_9.png',
  'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Thumbnails/chapter_10.png',
  'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Thumbnails/chapter_11.png',
];


export const lessonsDictionary = {
  1: {
    title: 'Algoritmul. Notiuni Fundamentale.',
    description:
      'Acest capitol introduce conceptele de bază ale programării și algoritmicii. Se începe cu o introducere generală în curs, urmată de definiția și importanța algoritmilor. Se discută despre date, variabile și constante, esențiale pentru stocarea și manipularea informațiilor în programe. Pseudocodul este prezentat ca un instrument pentru scrierea algoritmilor într-o formă ușor de înțeles, iar expresiile și operațiile de atribuire sunt explicate pentru a arăta cum se pot calcula și stoca noi valori. Capitolul include provocări practice (challenges) pentru a consolida înțelegerea conceptelor, precum și introducerea operațiilor de citire și scriere, deciziilor și structurilor repetitive, esențiale pentru controlul fluxului unui program.',
    urls: [
      'Modulul1/11.Intro curs.mov',
      'Modulul1/12.Algoritmul.mov',
      'Modulul1/13.Date.mov',
      'Modulul1/14.Constante.Variabile..mov',
      'Modulul1/15.Pseudocodul.mov',
      'Modulul1/16Expresii.mov',
      'Modulul1/17Operatia de atribuire 1.mov',
      'Modulul1/18Exemple atribuiri.mov',
      'Modulul1/19Challenge1.mov',
      'Modulul1/110a=b b=a.mov',
      'Modulul1/111Operatia in:out.mov',
      'Modulul1/112Borcan si Challenge 2.mov',
      'Modulul1/113Operatia de decizie.mov',
      'Modulul1/114Programarea structurata.mov',
      'Modulul1/115Exemplu liniar. Structura alternativa..mov',
      'Modulul1/116Paradigma.mov',
      'Modulul1/117Structura repetitiva.mov',
      'Modulul1/118Transformare while - for. Cifrele unui numar..mov',
      'Modulul1/119Continuare structuri repetitive.mov',
      'Modulul1/120Suma numerelor.mov',
    ],
    liveSession: [1, 2],
    lessons: [
      'Introducere',
      'Algoritmul',
      'Datele',
      'Variabile si constante',
      'Pseudocodul',
      'Expresii',
      'Operatia de atribuire',
      'Exemple atribuiri',
      'Challenge1',
      'a=b sau b=a?',
      'Operatia de citire si de scriere',
      'Challenge 2',
      'Operatia de decizie',
      'Programarea structurata',
      'Structura liniara. Structura alternativa.',
      'Paradigma programarii structurate',
      'Structura repetitiva 1',
      'Transformare for in while. Exemplu prelucrare cifrele unui numar.',
      'Structura repetitiva 2',
      'Suma numerelor naturale de la 1 la n si transformare structura repetitiva.',
    ],
  },
  2: {
    title: 'Introducere in limbajul de programare C++',
    // liveSession: 2,
    description:
      'Acest modul se concentrează pe fundamentalele limbajului C++, începând cu structura generală a unui program și rolul funcției main. Se explică sintaxa și semantica limbajului, tipurile de date disponibile, și cum să se utilizeze variabilele și constantele împreună cu operatorii aritmetici și logici. Se abordează operatorii de comparație și egalitate, urmați de lecții practice și challenges care implică operații de intrare/ieșire și diverse instrucțiuni de control al fluxului, cum ar fi if, switch, while, do while, și for.',
    urls: [
      'Modulul2/21Structura generala.mov',
      'Modulul2/22.Functia main.mov',
      'Modulul2/23.Sintaxa.mov',
      'Modulul2/24.Tipuri de date.mov',
      'Modulul2/25.Variabile si Constante. Op aritmetici..mov',
      'Modulul2/26.Operatori logici.mov',
      'Modulul2/27.Operatori de comparatie si egalitate..mov',
      'Modulul2/28.Challenge 1. Expresii..mov',
      'Modulul2/29.Challenge 2 redone.mov',
      'Modulul2/210. Operatia de intrare si iesire.mov',
      'Modulul2/211.Instructiuni.mov',
      'Modulul2/212.Instructiunea if.mov',
      'Modulul2/213. Instructiunea switch.mov',
      'Modulul2/214.Instructiunea while.mov',
      'Modulul2/215.Do while. For..mov',
    ],
    lessons: [
      'Structura generala a unui program C++',
      'Functia main',
      'Sintaxa. Semantica. Vocabular',
      'Tipuri de date',
      'Variabile si constante. Operatori aritmetici',
      'Operatori logici',
      'Operatori de comparatie si egalitate',
      'Challenge 1. Expresii',
      'Challenge 2',
      'Operatia de intrare si operatia de iesire',
      'Instructiuni',
      'Instructiunea IF',
      'Instructiunea SWITCH',
      'Instructiunea WHILE',
      'Instructiunea DO WHILE si instructiunea FOR',
    ],
  },
  3: {
    title: 'Subprograme',
    description:
      'Capitolul despre subprograme detaliază cum se pot crea funcții în C++, începând cu introducerea conceptului și importanța utilizării funcțiilor pentru a structura și organiza codul. Se discută despre prototipuri, variabile locale și globale, și cum acestea influențează domeniul de vizibilitate al datelor într-un program. De asemenea, se explică transmiterea parametrilor către funcții, un aspect crucial pentru manipularea datelor și realizarea de operații complexe.',
    urls: [
      'Modulul3/1. Intro.mov',
      'Modulul3/2.Cum cream o functie.mov',
      'Modulul3/3.Prototipul.mov',
      'Modulul3/Variabile locale si globale.mov',
      'Modulul3/5.Exemplu.mov',
      'Modulul3/6.Transmiterea parametrilor.mov',
    ],
    lessons: [
      'Introducere in subprograme',
      'Cum cream o functie?',
      'Prototipul',
      'Variabile locale si globale',
      'Domeniul de vizibilitate',
      'Transmiterea parametrilor',
    ],
  },
  4: {
    title: 'Tipuri structurate de date',
    liveSession: [3, 4],
    description:
      'Acest modul explorează structurile de date mai complexe, precum vectorii și matricile, esențiale pentru stocarea și manipularea colecțiilor de date. Se discută despre inițializarea și utilizarea acestora, inclusiv lucrul cu siruri de caractere și funcții predefinite pentru manipularea acestora. Capitolul abordează și conceptele de pointeri și tipul înregistrare, oferind studenților o bază solidă pentru gestionarea memoriei și structurilor de date complexe.',
    urls: [
      'Modulul4/1. Intro. Vectori.mov',
      'Modulul4/2.Exemplu cod.mov',
      'Modulul4/3. Initializarea vectorului.mov',
      'Modulul4/4. Matrice.mov',
      'Modulul4/5. Continuare + Matricea patratica.mov',
      'Modulul4/6. Siruri de caractere.mov',
      'Modulul4/7. Functii predefinite.mov',
      'Modulul4/8. Exemplu functii predefinite.mov',
      'Modulul4/9.Sirurile de caractere si pointerii.mov',
      'Modulul4/10.Pointeri.Challenge..mov',
      'Modulul4/11.Strtok.mov',
      'Modulul4/12.Tipul Inregistrare.mov',
      'Modulul4/13. Accesare campuri. Challenge..mov',
    ],
    lessons: [
      'Introducere. Vectori.',
      'Exemplu cod',
      'Initializarea vectorului',
      'Matricea',
      'Continuare matrice. Matricea patratica.',
      'Siruri de caractere',
      'Functii predefinite pentru sirurile de caractere',
      'Challenge pointeri',
      'Exemplu functii predefinite',
      'Siruri de caractere si pointeri',
      'Functia strtok',
      'Tipul inregistrare',
      'Accesarea campurilor. Challenge',
    ],
  },
  5: {
    title: 'Lucrul cu fisiere',
    description:
      'Se introduce conceptul de lucrul cu fișiere, explicând cum se pot realiza operații de citire și scriere în fișiere externe. Acest capitol este esențial pentru înțelegerea modului în care programele interacționează cu sistemul de fișiere al unui computer, permițând persistența datelor dincolo de execuția programului.',
    urls: [
      'Modulul5/1.Intro fisiere.mov',
      'Modulul5/2.Operatii cu fisiere.mov',
      'Modulul5/3.Exemplu.mov',
    ],
    lessons: ['Introducere fisiere', 'Operatii cu fisiere', 'Exemplu'],
  },
  6: {
    title: 'Algoritmi elementari. Sortari si cautari. Complexitate.',
    liveSession: [8],
    description:
      'Acest modul acoperă algoritmi fundamentali de sortare și căutare, precum și conceptul de complexitate algoritmică. Se începe cu operații elementare pe numere, urmate de prezentarea algoritmilor de sortare (Bubble Sort, Insertion Sort, Selection Sort) și căutare (secvențială și binară). Se discută despre complexitatea temporală și spațială, oferind studenților instrumentele necesare pentru a evalua și îmbunătăți eficiența algoritmilor.',
    urls: [
      'Modulul6/1.Intro.mov',
      'Modulul6/2.Cifrele unui numar.mov',
      'Modulul6/3.Exemplu cifrele unui numar.mov',
      'Modulul6/4.Divizori.Numere prime..mov',
      'Modulul6/5.Exemplu.mov',
      'Modulul6/6.CMMDC.mov',
      'Modulul6/61.CMMDC Exemplu.mov',
      'Modulul6/7.Fibonacci. Sume cu termen dat..mov',
      'Modulul6/8.Fibo exemplu.mov',
      'Modulul6/9.Minim.Maxim.mov',
      'Modulul6/10.Exemplu minim si maxim.mov',
      'Modulul6/11.Sortarea.Bubble Sort..mov',
      'Modulul6/12.Insertion sort.mov',
      'Modulul6/13.Selection sort.mov',
      'Modulul6/14.Metoda numararii.mov',
      'Modulul6/15.Interclasarea a doi vectori.mov',
      'Modulul6/16.Metode de cautare.Cautarea secventiala.mov',
      'Modulul6/17.Cautarea Binara.mov',
      'Modulul6/18.Complexitatea.mov',
      'Modulul6/19.Tipuri de complexitate.mov',
    ],
    lessons: [
      'Despre algoritmii elementari',
      'Cifrele unui numar',
      'Program cifrele unui numar',
      'Divizori. Numere prime',
      'Program divizori si numere prime',
      'CMMDC',
      'Program CMMDC',
      'Fibonacci. Sume cu termen general dat',
      'Program Fibonacci',
      'Minim. Maxim.',
      'Program minim si maxim.',
      'Sortari. Bubble sort.',
      '[BONUS] Insertion sort.',
      '[BONUS] Selection sort',
      '[BONUS] Sortarea prin metoda numararii',
      'Interclasarea a doi vectori',
      'Metode de cautare. Cautarea secventiala',
      'Cautarea binara',
      'Complexitatea',
      'Tipuri de complexitate',
    ],
  },
  7: {
    title: 'Grafuri. Arbori',
    description:
      'Introduce conceptele fundamentale legate de grafuri și arbori, incluzând terminologia specifică, tipuri de grafuri (neorientate, orientate), proprietăți (conexitate, cicluri, lanturi), și metode de reprezentare. Se discută despre algoritmi specifici pentru parcurgerea și analiza structurilor de date complexe, precum grafurile și arborii.',
    liveSession: [5, 6],
    urls: [
      'Modulul9/1.Intro.mov',
      'Modulul9/2.Terminologie.mov',
      'Modulul9/3.Teorema grad.mov',
      'Modulul9/4.Lanturi.Cicluri..mov',
      'Modulul9/5.Graf partial,complementar,subgraf.mov',
      'Modulul9/6.Conexitatea.mov',
      'Modulul9/7.Graf hamiltonian si eulerian.mov',
      'Modulul9/8.Metode de reprezentare.mov',
      'Modulul9/9.Grafuri orientate.mov',
      'Modulul9/10.Graf partial.Subgraf..mov',
      'Modulul9/11.Conexitate.Grafuri hamiltoniene,euleriene.mov',
      'Modulul9/12.Arbori.mov',
      'Modulul9/13.Metode de reprezentare.mov',
    ],
    lessons: [
      'Introducere grafuri',
      'Grafuri neorientate. Extremitati. Noduri. Muchii. Incidenta. Grad',
      'Teorema grad',
      'Lanturi. Cicluri',
      'Graf partial. Subgraf. Graf complementar.',
      'Conexitatea',
      'Graf hamiltonian si eulerian',
      'Metode de reprezentare',
      'Drum. Circuit. Graf partial. Subgraf',
      'Grafuri orientate. Extremitati. Noduri. Arce. Incidenta. Grade',
      'Conexitate. Tare conexitate. Graf hamiltonian si eulerian',
      'Arbori. Terminologie',
      'Metode de reprezentare',
    ],
  },

  8: {
    title: 'Backtracking. Combinatorica',
    liveSession: [7],
    description:
      'Se abordează tehnica de backtracking și aplicarea acesteia în probleme de combinatorică, cum ar fi generarea permutărilor, combinațiilor, aranjamentelor și calculul produsului cartezian. Acest capitol îi ajută pe studenți să înțeleagă cum se pot explora spații de căutare complexe pentru a găsi soluții la probleme specifice.',
    urls: [
      'Modulul8/1.Intro.mov',
      'Modulul8/2.Teorie Backtracking.mov',
      'Modulul8/3.Exemplu bac.mov',
      'Modulul8/4.Permutari.Combinari.mov',
      'Modulul8/5.Aranjamente, submultimi si produs cartezian.mov',
    ],
    lessons: [
      'Introducere backtracking',
      'Teorie backtracking',
      'Exemplu',
      'Permutari. Combinari',
      'Aranjamente. Submultimi. Produs cartezian',
    ],
  },
  9: {
    title: 'Recursivitate',
    description:
      'Capitolul despre recursivitate explorează conceptul și utilizarea recursivității în programare, cu exemple specifice precum calculul seriei Fibonacci și factorialul unui număr. Se discută despre stiva de execuție și cum recursivitatea se aplică în rezolvarea problemelor complexe prin descompunerea acestora în subprobleme mai mici.',

    urls: [
      'Modulul7/1.Intro recursivitate.mov',
      'Modulul7/2.Stiva.mov',
      'Modulul7/3.Fibo.mov',
      'Modulul7/4.Fibo exemplu.mov',
      'Modulul7/5.Factorial recursiv.mov',
    ],
    lessons: [
      'Introducere recursivitate',
      'Stiva',
      'Fibonacci recursiv',
      'Program Fibonacci recursiv',
      'Factorial recursiv. Program factorial recursiv.',
    ],
  },

  10: {
    title: 'Rezolvare integrala subiect BAC',
    description:
      'Acest modul este dedicat pregătirii pentru examenul de Bacalaureat, oferind o revizuire completă prin rezolvarea integrală a tipurilor de subiecte întâlnite la examen. Se acoperă toate aspectele importante, de la structuri de date, algoritmi elementari, până la subprograme și lucrul cu fișiere, asigurând o pregătire solidă pentru examen.',
    urls: [
      'Modulul10/1.Intro bac.mov',
      'Modulul10/2.11.mov',
      'Modulul10/3.12.mov',
      'Modulul10/4.13.mov',
      'Modulul10/5.14.mov',
      'Modulul10/6.15.mov',
      'Modulul10/7.Intro 2.mov',
      'Modulul10/8.2.1a.mov',
      'Modulul10/9.21b.mov',
      'Modulul10/10.21c.mov',
      'Modulul10/11.21d.mov',
      'Modulul10/12.22 full.mov',
      'Modulul10/13.23.mov',
      'Modulul10/14.Intro 3.mov',
      'Modulul10/15.31 part 1.mov',
      'Modulul10/16. 31 part 2.mov',
      'Modulul10/17.31 part 3.mov',
      'Modulul10/18.32 part 1.mov',
      'Modulul10/19.32 part 2.mov',
      'Modulul10/20. 32 part 3.mov',
      'Modulul10/21.33 part 1.mov',
      'Modulul10/22. .mov',
      'Modulul10/23.Citire fisier.mov',
    ],
    lessons: [
      'Introducere',
      'Subiectul 1 exercitiul 1',
      'Subiectul 1 exercitiul 2',
      'Subiectul 1 exercitiul 3',
      'Subiectul 1 exercitiul 4',
      'Subiectul 1 exercitiul 5',
      'Introducere subiect 2',
      'Subiectul 2 exercitiul 1a',
      'Subiectul 2 exercitiul 1b',
      'Subiectul 2 exercitiul 1c',
      'Subiectul 2 exercitiul 1d',
      'Subiectul 2 exercitiul 2',
      'Subiectul 2 exercitiul 3',
      'Introducere subiect 3',
      'Subiectul 3 exercitiul 1 partea 1',
      'Subiectul 3 exercitiul 1 partea 2',
      'Subiectul 3 exercitiul 1 partea 3',
      'Subiectul 3 exercitiul 2 partea 1',
      'Subiectul 3 exercitiul 2 partea 2',
      'Subiectul 3 exercitiul 2 partea 3',
      'Subiectul 3 exercitiul 3 partea 1',
      'Subiectul 3 exercitiul 3 partea 2',
      'Subiectul 3 exercitiul 3 citire din fisier (video)',
    ],
  },
  11: {
    title: 'Ghid de instalare programe',
    description:
      'Oferă instrucțiuni pas cu pas pentru instalarea mediilor de dezvoltare și a uneltelor necesare programării în C++ pe diferite sisteme de operare, asigurând că studenții pot începe să scrie și să execute programe pe propriile lor dispozitive.',
    urls: [
      'Install/Ghid.mov',
      'Install/Bac Info.mov',
      'Install/2.Windows 2.mov',
      'Install/3. Mac .mov',
    ],
    lessons: [
      'Introducere',
      'Windows: descarcare Code::Blocks',
      'Windows: descarcare Code::Blocks',
      'MacOs: descarcare si instalare Xcode',
    ],
  },
};

export const customData = {
  'rezolvari-bac': {
    chapter: 'Rezolvare subiect bac',
    videos: [
      'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/rezolvari-exercitii/Varianta+TEST+++subiectul+I.mp4',
      'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/rezolvari-exercitii/Varianta+TEST+++subiectul+II.mp4',
      'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/rezolvari-exercitii/Varianta+TEST+++subiectul+III+p1.mp4',
      'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/rezolvari-exercitii/Varianta+TEST+++subiectul+III+p2.mp4',
      'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/rezolvari-exercitii/Varianta+TEST+++subiectul+III+p3.mp4',
    ],
    titles: [
      'Variantă BAC subiectul I',
      'Variantă BAC subiectul II',
      'Variantă BAC subiectul III problema 1',
      'Variantă BAC subiectul I problema 2',
      'Variantă BAC subiectul I problema 3',
    ],
  },
  'grafuri-materiale-aditionale': {
    chapter: 'Materiale adiționale grafuri',
    videos: [
      'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Grafuri_Arbori_Aditional/Grafuri+pentru+BAC+-+partea+1.mp4',
      'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Grafuri_Arbori_Aditional/Grafuri+pentru+BAC+-+partea+2.mp4',
      'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Grafuri_Arbori_Aditional/Grafuri+pentru+BAC+-+partea+3.mp4',
      'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Grafuri_Arbori_Aditional/Simulare+2022+-+Exercitiul+5.mp4',
      'https://curs-info-teorie.s3.eu-central-1.amazonaws.com/Grafuri_Arbori_Aditional/Simulare+2022+-+Exercitiul+2.2.mp4',
    ],
    titles: [
      'Teorie adițională grafuri partea 1',
      'Teorie adițională grafuri partea 2',
      'Teorie adițională grafuri partea 3',
      'Simulare 2022 exercițiul 1.5',
      'Simulare 2022 exercițiul 2.2',
    ],
  },
};

export const liveSessionssHeld = 12;
export const currentSessionStartDate = new Date('2023', '00', '20', 14, 0, 0);
export const sessionModule = [
  'Algoritmul. Notiuni Fundamentale.',
  'Exerciții pseudocod',
  'Tipuri structurate de date. Tablouri',
  'Tipuri structurate de date. Siruri de Caractere. Tipul Struct.',
  'Grafuri',
  'Arbori',
  'Combinatorica. Backtracking. Recursivitate',
  'Algoritmi Elementari. Subprograme.',
  'Subprograme. Subiectul 3 exercitiul 1',
  'Algoritmi. Subiectul 3 exercitiul 2',
  'Fisiere. Subiectul 3 exercitiul 3',
  'Subiect BAC no. 1',
];
export const sessionDates = [
  new Date('2024', '00', '20', 14, 0, 0),
  new Date('2024', '00', '27', 14, 0, 0),
  new Date('2024', '01', '03', 14, 0, 0),
  new Date('2024', '01', '10', 14, 0, 0),
  new Date('2024', '01', '24', 14, 0, 0),
  new Date('2024', '02', '02', 14, 0, 0),
  new Date('2024', '02', '09', 14, 0, 0),
  new Date('2024', '02', '23', 14, 0, 0),
  new Date('2024', '02', '30', 14, 0, 0),
  new Date('2024', '03', '6', 14, 0, 0),
  new Date('2024', '03', '13', 14, 0, 0),
  new Date('2024', '03', '20', 14, 0, 0),
];

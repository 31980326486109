import { configureStore } from '@reduxjs/toolkit';
import checkCurrentUserReducer from './features/checkCurrentUserSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage: storageSession,
};
const persistedReducer = persistReducer(persistConfig, checkCurrentUserReducer);

export const store = configureStore({
  reducer: {
    checkCurrentUser: persistedReducer,
  },
  middleware: [thunk],
});

export const persistor = persistStore(store);

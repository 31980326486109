import {
  Center,
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Container,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import BackToDashboard from '../../components/BackToDashboard/BackToDashboard';
import {
  lessonsDictionary,
  hasHomework,
  sessionModule,
  additionalInfo,
} from '../../constants';
import { Link } from 'react-router-dom';

function CourseIndex() {
  return (
    <>
      <Container maxW="container.xl">
        {' '}
        <BackToDashboard />
      </Container>

      <Center>
        <Box mt="5">
          <Heading>Modulul I: Cuprins Teorie</Heading>
        </Box>
      </Center>
      <Box h={20} />

      <Container maxW="container.xl">
        <TableContainer size="sm" fontSize={20}>
          <Table
            variant="striped"
            colorScheme={'purple'}
            size="sm"
            sx={{ tableLayout: 'fixed' }}
          >
            <Thead>
              <Tr>
                {/* <Th>Data</Th> */}
                <Th>Capitol</Th>
                <Th>Teorie</Th>
                <Th>Exerciții</Th>
                <Th>Sesiuni Live</Th>
                <Th>Materiale</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.entries(lessonsDictionary).map(([k, v], i) => {
                return (
                  <>
                    <Tr>
                      {/* <Td>
                        {new Date(sessionDates[i]).toLocaleDateString('ro')}
                      </Td> */}
                      <Td
                        sx={{
                          wordWrap: 'break-word',
                          whiteSpace: 'normal',
                          fontSize: '16',
                          lineHeight: '1.5',
                        }}
                      >
                        <b>📎 {v['title']}</b>
                      </Td>

                      <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                        {' '}
                        <Link to={`/module-${k}/1`} as={'button'} w={'100%'}>
                          <Text
                            px={3}
                            lineHeight={10}
                            borderRadius={'12px'}
                            _hover={{ background: 'purple.200' }}
                          >
                            🖥️ Video-uri Teorie
                          </Text>
                        </Link>
                      </Td>
                      <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                        {hasHomework[i] !== false && (
                          <>
                            <Link
                              to={`/exercitii/${hasHomework[i]}`}
                              as={'button'}
                              w={'100%'}
                            >
                              <Text
                                borderRadius={'12px'}
                                px={3}
                                lineHeight={10}
                                _hover={{ background: 'purple.200' }}
                              >
                                📚 Exerciții Temă
                              </Text>
                            </Link>
                            <Link
                              to={`https://drive.google.com/drive/folders/1xR5UIX6m98N2ECbpM34wQlbFBMcbtsDq?usp=drive_link`}
                              as={'button'}
                              w={'100%'}
                            >
                              <Text
                                borderRadius={'12px'}
                                px={3}
                                lineHeight={10}
                                _hover={{ background: 'purple.200' }}
                              >
                                ⏫ Drive Tema
                              </Text>
                            </Link>
                          </>
                        )}
                      </Td>
                      <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                        {v['liveSession'] !== undefined &&
                          v['liveSession'].map((e, i) => (
                            <Link
                              to={`/sesiuni-live/${v['liveSession'][i]}`}
                              as={'button'}
                              w={'100%'}
                            >
                              <Text
                                borderRadius={'12px'}
                                px={3}
                                lineHeight={10}
                                _hover={{ background: 'purple.200' }}
                              >
                                {(e - 1) % 2 === 0 ? '👩🏽‍🏫' : '🧑🏻‍🏫'} #{e}{' '}
                                {sessionModule[e - 1]}
                              </Text>
                            </Link>
                          ))}
                      </Td>
                      <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                        {additionalInfo[i] !== false &&
                          additionalInfo[i].map((e, i) => {
                            return (
                              <Link to={e['link']} as={'button'} w={'100%'}>
                                <Text
                                  borderRadius={'12px'}
                                  px={3}
                                  lineHeight={10}
                                  _hover={{ background: 'purple.200' }}
                                >
                                  📌 {e['name']}
                                </Text>
                              </Link>
                            );
                          })}
                      </Td>
                    </Tr>
                  </>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>

        <Box h={20} />
        <Center>
          <Box mt="5">
            <Heading>Subiectul III</Heading>
          </Box>
        </Center>
        <Box h={20} />

        <TableContainer size="sm">
          <Table
            fontSize={18}
            variant="striped"
            colorScheme="purple"
            size="sm"
            sx={{ tableLayout: 'fixed' }}
          >
            <Thead>
              <Tr>
                {/* <Th>Data</Th> */}
                <Th>Exercitiu Subiectul III</Th>
                <Th>Sesiune LIVE</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <b>Subprograme. Subiectul III exercitiul 1</b>
                </Td>
                <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  <Link to={'/sesiuni-live/9'} as={'button'} w={'100%'}>
                    <Text
                      borderRadius={'12px'}
                      px={3}
                      lineHeight={10}
                      _hover={{ background: 'purple.200' }}
                    >
                      👩🏽‍🏫 Sesiune LIVE
                    </Text>
                  </Link>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <b>Subprograme. Subiectul III exercitiul 2</b>
                </Td>
                <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  <Link to={'/sesiuni-live/10'} as={'button'} w={'100%'}>
                    <Text
                      borderRadius={'12px'}
                      px={3}
                      lineHeight={10}
                      _hover={{ background: 'purple.200' }}
                    >
                      🧑🏻‍🏫 Sesiune LIVE
                    </Text>
                  </Link>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <b>Subprograme. Subiectul III exercitiul 3</b>
                </Td>
                <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  <Link to={'/sesiuni-live/11'} as={'button'} w={'100%'}>
                    <Text
                      borderRadius={'12px'}
                      px={3}
                      lineHeight={10}
                      _hover={{ background: 'purple.200' }}
                    >
                      👩🏽‍🏫 Sesiune LIVE
                    </Text>
                  </Link>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <Box h={20} />
        <Center>
          <Box mt="5">
            <Heading>Rezolvare live subiect BAC</Heading>
          </Box>
        </Center>
        <Box h={20} />

        <TableContainer size="sm">
          <Table
            fontSize={18}
            variant="striped"
            colorScheme="purple"
            size="sm"
            sx={{ tableLayout: 'fixed' }}
          >
            <Thead>
              <Tr>
                {/* <Th>Data</Th> */}
                <Th>Subiect BAC</Th>
                <Th>Link Inregistrare</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <b>Varianta 6 2020</b>
                </Td>
                <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  <Link to={'/sesiuni-live/12'} as={'button'} w={'100%'}>
                    <Text
                      borderRadius={'12px'}
                      px={3}
                      lineHeight={10}
                      _hover={{ background: 'purple.200' }}
                    >
                      👩🏽‍🏫 Sesiune LIVE
                    </Text>
                  </Link>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <Box h={20} />
        <Center>
          <Box mt="5">
            <Heading>Materiale Aditionale</Heading>
          </Box>
        </Center>
        <Box h={20} />
        <TableContainer size="sm">
          <Table
            fontSize={18}
            variant="striped"
            colorScheme="purple"
            size="sm"
            sx={{ tableLayout: 'fixed' }}
          >
            <Thead>
              <Tr>
                {/* <Th>Data</Th> */}
                <Th>An</Th>
                <Th>Video Rezolvări</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <b>Subiect Creat de Noi</b>
                </Td>
                <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  <Link to={'/rezolvari-bac/1'} as={'button'} w={'100%'}>
                    <Text
                      borderRadius={'12px'}
                      px={3}
                      lineHeight={10}
                      _hover={{ background: 'purple.200' }}
                    >
                      📌 Video Rezolvări Integrale
                    </Text>
                  </Link>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <b>Simulare 2022</b>
                </Td>
                <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  <Link
                    to={
                      'https://www.youtube.com/playlist?list=PLFirk-PO05GUtd4dXNix5NsQ1p3VoHJ13'
                    }
                    as={'button'}
                    w={'100%'}
                  >
                    <Text
                      borderRadius={'12px'}
                      px={3}
                      lineHeight={10}
                      _hover={{ background: 'purple.200' }}
                    >
                      📌 Video Rezolvări Integrale
                    </Text>
                  </Link>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <b>Arhiva: G1</b>
                </Td>
                <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  <Link
                    to={
                      'https://www.youtube.com/playlist?list=PLFirk-PO05GUtO7Jwa2QGsxsF7RsXdszE'
                    }
                    as={'button'}
                    w={'100%'}
                  >
                    <Text
                      borderRadius={'12px'}
                      px={3}
                      lineHeight={10}
                      _hover={{ background: 'purple.200' }}
                    >
                      📌 Sesiuni LIVE
                    </Text>
                  </Link>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <b>Arhiva: G2</b>
                </Td>
                <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  <Link
                    to={
                      'https://www.youtube.com/playlist?list=PLFirk-PO05GUkeKXwz7rzXGvnIBQde2j_'
                    }
                    as={'button'}
                    w={'100%'}
                  >
                    <Text
                      borderRadius={'12px'}
                      px={3}
                      lineHeight={10}
                      _hover={{ background: 'purple.200' }}
                    >
                      📌 Sesiuni LIVE
                    </Text>
                  </Link>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <b>Arhiva: G3</b>
                </Td>
                <Td sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  <Link
                    to={
                      'https://www.youtube.com/playlist?list=PLFirk-PO05GVMAoBI2YGpxEd1dab51Q-y'
                    }
                    as={'button'}
                    w={'100%'}
                  >
                    <Text
                      borderRadius={'12px'}
                      px={3}
                      lineHeight={10}
                      _hover={{ background: 'purple.200' }}
                    >
                      📌 Sesiuni LIVE
                    </Text>
                  </Link>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}

export default CourseIndex;
